import React, {useState} from 'react';
import { push as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';

import LVCIconW from "../../images/icons/LVCIconW.svg"
import aboutLVCIconW from "../../images/icons/aboutLVCIconW.svg"
import BPIconW from "../../images/icons/BPIconW.svg"

import './Nav.css';

const Nav = ({
  outerContainerId,
  pageWrapId,
}) => {
  const [selected, setSelected] = useState("home");
  const onClick = (param) => {
    setSelected(param)
  }
  return (
    <Menu pageWrapId={pageWrapId} outerContainerId={outerContainerId}>
      <div>
        <div className='font-bold'>The Liver Volume Calculator</div>
        <hr className='my-5'></hr>

        <Link id="home" className="menu-item" to="/">
          <div onClick={() => onClick("home")} className={`item-container ${selected =="home"?'item-container-selected' : ''}`}>
            <img src={LVCIconW} className='size-1 my-auto max-w-3 max-h-7'></img>
            <span>Liver Volume Calculator</span>
          </div>
        </Link>

        <Link id="aboutLVC" className="menu-item" to="/aboutLVC">
          <div onClick={()=>onClick("aboutLVC")} className={`item-container ${selected =="aboutLVC"?'item-container-selected' : ''}`}>
            <img src={aboutLVCIconW} className='size-1 my-auto max-w-3 max-h-7'></img>
            <span>About the L.V.C.</span>
          </div>
        </Link>
        <Link id="aboutBP" className="menu-item" to="/aboutBP">
          <div onClick={()=>onClick("aboutBP")} className={`item-container ${selected =="aboutBP"?'item-container-selected' : ''}`}>
            <img src={BPIconW} className='size-1 my-auto max-w-3 max-h-7'></img>
            <span>About Blue People</span>
          </div>
        </Link>
      </div>
      
        <div className='footer'>
          <hr className='my-5'></hr>
          <span className='font-bold'>
          Disclaimer:
          </span>
          <br></br>
          <br></br>
          <span className='text-xs font-light leading-none'>The liver volume calculation provided by this web application is intended for informational purposes only. It should not be used as a substitute for professional medical advice, diagnosis, or treatment. Users should consult with a qualified healthcare professional regarding any medical concerns or decisions. We do not guarantee the accuracy, completeness, or reliability of the information provided herein. The use of this web application is at the user's own risk. The medical company specialized in oncology bears no responsibility for any actions taken based on the results obtained from this application.</span>
        </div>
      
    </Menu>
  );
};

export default Nav;
import { useState } from "react"
import InputText from "./InputText"
import TagValue from "./TagValue"
import Card from "./Card"
import { Formik, Form, Field } from "formik"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import useWindowDimensions from "../hooks/windowDimensions"

const Main = () => {
  const { height, width, isMobile } = useWindowDimensions();
  const abbrevWidth = 767
  const [calculatedValues, setCalculatedValues] = useState({
    pre: {
      formula1: {
        seg_1_2_3: 0,
        seg_1_2_3_4: 0,
        seg_2_3: 0,
        BSA: 0,
        BMI: 0,
        TEL: 0,
        total_seg_1_2_3: 0,
        total_seg_1_2_3_4: 0,
        total_whole_liver: 0
      },
      formula2: {
        TEL: 0,
        total_seg_1_2_3: 0,
        total_seg_1_2_3_4: 0,
        total_seg_2_3: 0,
        total_whole_liver: 0
      },
    },
    post: {
      formula1: {
        seg_1_2_3: 0,
        seg_1_2_3_4: 0,
        seg_2_3: 0,
        BSA: 0,
        BMI: 0,
        TEL: 0,
        total_seg_1_2_3: 0,
        total_seg_1_2_3_4: 0,
        total_whole_liver: 0
      },
      formula2: {
        TEL: 0,
        total_seg_1_2_3: 0,
        total_seg_1_2_3_4: 0,
        total_seg_2_3: 0,
        total_whole_liver: 0
      },
      KGR: {
        seg_1_2_3: 0,
        seg_1_2_3_4: 0,
        seg_2_3: 0,
      }
    }
  })
  const [isPostEmbo, setIsPostEmbo] = useState(false)

  const calculateFormula1 = (values, isPost) => {
    const seg_1_2_3 = isPost ? values.postSegment1 + values.postSegment2 + values.postSegment3 : values.preSegment1 + values.preSegment2 + values.preSegment3
    const seg_1_2_3_4 = isPost ? seg_1_2_3 + values.postSegment4 : seg_1_2_3 + values.preSegment4
    const seg_2_3 = isPost ? values.postSegment2 + values.postSegment3 : values.preSegment2 + values.preSegment3
    const ht_wt = isPost ? values.postHeight * values.postWeight : values.preHeight * values.preWeight
    const ht_ht = isPost ? values.postHeight * values.postHeight : values.preHeight * values.preHeight
    const BSA = Math.sqrt(ht_wt / 3600);
    const BMI = (isPost ? values.postWeight : values.preWeight) / ht_ht * 10000;
    const TEL = (706.2 * BSA) + 2.4

    return {
      seg_1_2_3,
      seg_1_2_3_4,
      seg_2_3,
      BSA,
      BMI,
      TEL,
      total_seg_1_2_3: seg_1_2_3 / TEL,
      total_seg_1_2_3_4: seg_1_2_3_4 / TEL,
      total_whole_liver: (isPost ? values.postLiver : values.preLiver) / TEL,
    };
  };

  const calculateFormula2 = (formula1, emboLiver) => {
    const TEL = 1267.4 * formula1.BSA - 794.41;

    return {
      TEL,
      total_seg_1_2_3: formula1.seg_1_2_3 / TEL,
      total_seg_1_2_3_4: formula1.seg_1_2_3_4 / TEL,
      total_seg_2_3: formula1.seg_2_3 / TEL,
      total_whole_liver: emboLiver / TEL
    };
  };

  const calculateKGR = (parameters) => {
    const { preValues, postValues, PVEDate, CTEDate } = parameters
    const aux = (new Date(CTEDate) - new Date(PVEDate)) / (24 * 60 * 1000 * 60) / 7
    return {
      seg_1_2_3: (postValues.total_seg_1_2_3 - preValues.total_seg_1_2_3) / aux,
      seg_1_2_3_4: (postValues.total_seg_1_2_3_4 - preValues.total_seg_1_2_3_4) / aux,
      seg_2_3: (postValues.total_seg_2_3 - preValues.total_seg_2_3) / aux
    };
  };

  return (
    <div className="poweredbyBluepeople grid h-full grid-row">
      <Formik
        initialValues={{
          preLiver: 0,
          preSegment1: 0,
          preSegment2: 0,
          preSegment3: 0,
          preSegment4: 0,
          preHeight: 0,
          preWeight: 0,
          postLiver: 0,
          postSegment1: 0,
          postSegment2: 0,
          postSegment3: 0,
          postSegment4: 0,
          postHeight: 0,
          postWeight: 0,
        }}
        validate={(values) => {
          const errors = {};

          let numericFields = ['preLiver', 'preSegment1', 'preSegment2', 'preSegment3', 'preSegment4', 'preHeight', 'preWeight']
          
          if (new Date(values.PVEDate) > new Date()) {
            errors.PVEDate = 'PVE Date must be before today';
          } 
          
          if (isPostEmbo) {
            if (!values.PVEDate) {
              errors.PVEDate = 'PVE Date is required';
            } 
            numericFields = numericFields.concat('postLiver', 'postSegment1', 'postSegment2', 'postSegment3', 'postSegment4', 'postHeight', 'postWeight')
            if (!values.CTEDate) {
              errors.CTEDate = 'CTE Date is required';
            }
            if (new Date(values.CTEDate) < new Date(values.PVEDate)) {
                errors.CTEDate = 'CTE Date must be after PVE Date';
            }
          }

          numericFields.forEach(field => {
            if (values[field] <= 0) {
              errors[field] = 'The value must be greater than 0';
            }
          });

          return errors;
        }}
        onSubmit={values => {
          const formula1 = calculateFormula1(values, false)
          const formula2 = calculateFormula2(formula1, values.preLiver)
          
          let _calculatedValues = ({
            ...calculatedValues,
            pre: {
              formula1,
              formula2,
            }
          })

          if (isPostEmbo) {
            const formula1 = calculateFormula1(values, true)
            const formula2 = calculateFormula2(formula1, values.postLiver)
            const KGRparameters = {
              preValues: _calculatedValues.pre.formula2,
              postValues: formula2,
              PVEDate: values.PVEDate,
              CTEDate: values.CTEDate
            }
            const KGR = calculateKGR(KGRparameters)
            _calculatedValues = ({
              ..._calculatedValues,
              post: {
                formula1,
                formula2,
                KGR
              }
            })
          }
          setCalculatedValues(_calculatedValues)
        }}
      >
        {({ errors, touched }) => (
          <Form className="grid grid-cols-2 text-center m-5 gap-5">
            <div className="col-span-12 lg:col-span-2 grid grid-cols-12 -mb-2">
              <div className="col-span-10 col-start-2 mx-2">
                <div className="grid grid-cols-12 mx-2">
                  <span className="col-span-12 text-3xl font-bold text-center m-1">Welcome to the Liver Volume Calculator</span>
                  <hr className="col-span-12 border-black m-1">
                  </hr>
                  
                  <div className=" col-span-12 flex mx-2">
                    <span className="font-bold text-right m-1">First: What’s the patient’s  PVE Date: </span>
                    <div className="max-w-0.5">
                          <Field name="PVEDate">
                            {({ field, form }) => (
                              <DatePicker
                                dateFormat="MM-dd-yyyy"
                                className="shrink-0 border p-1 rounded-md"
                                id="PVEDate"
                                selected={field.value}
                                onChange={(date) => form.setFieldValue('PVEDate', date)}
                              />
                            )}
                          </Field> 
                          <div className="mb-2 text-xs text-red-600 text-center mx-2">
                            {errors.PVEDate ?? '‎'}
                          </div>
                    </div>
                  </div>
                  <div className=" col-span-12 flex mx-2">
                    <span className="font-bold text-right m-1">
                    Then fill out the following data as appropriate:
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-1">
              <Card title={'Pre Embo Liver Volume'}>
                <div className="col-span-6">
                  <div className="grid grid-cols-12 mx-2">
                    <span className="font-bold col-span-5 text-right m-1">CTE Date</span>
                    <div className="col-span-5">
                      <Field name="preDate">
                        {({ field, form }) => (
                          <DatePicker
                            {...field}
                            dateFormat="MM-dd-yyyy"
                            className="border p-1 rounded-md col-span-2"
                            id="preDate"
                            selected={field.value}
                            onChange={(date) => form.setFieldValue('preDate', date)}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="mb-2 text-xs text-red-600 text-center mx-2">
                    {errors.preDate ?? '‎'}
                  </div>
                </div>
                <InputText
                  label="Weight"
                  name="preWeight"
                  placeholder="0"
                  suffix="kg"
                  error={errors.preWeight}
                  touched={touched.preWeight}
                />
                <InputText
                  label="Height"
                  name="preHeight"
                  placeholder="0"
                  suffix="cm"
                  error={errors.preHeight}
                  touched={touched.preHeight}
                />
                <TagValue
                  label="BMI"
                  value={(calculatedValues.pre?.formula1.BMI || 0)}
                />
                <InputText
                  label="Liver"
                  name="preLiver"
                  placeholder="0"
                  suffix="cm&#179;"
                  error={errors.preLiver}
                  touched={touched.preLiver}
                />
                <InputText
                  label={isMobile || width <= abbrevWidth ? "S1" : "Segment 1"}
                  name="preSegment1"v
                  placeholder="0"
                  suffix="cm&#179;"
                  error={errors.preSegment1}
                  touched={touched.preSegment1}
                />
                <InputText
                  label={isMobile || width <= abbrevWidth ? "S2" : "Segment 2"}
                  name="preSegment2"
                  placeholder="0"
                  suffix="cm&#179;"
                  error={errors.preSegment2}
                  touched={touched.preSegment2}
                />
                <InputText
                  label={isMobile || width <= abbrevWidth ? "S3" : "Segment 3"}
                  name="preSegment3"
                  placeholder="0"
                  suffix="cm&#179;"
                  error={errors.preSegment3}
                  touched={touched.preSegment3}
                />
                <InputText
                  label={isMobile || width <= abbrevWidth ? "S4" : "Segment 4"}
                  name="preSegment4"
                  placeholder="0"
                  suffix="cm&#179;"
                  error={errors.preSegment4}
                  touched={touched.preSegment4}
                />
                <div className="grid grid-cols-3 col-span-6 mb-2">
                  <button
                    className="bg-black font-bold col-span-3 lg:col-span-1 xl:col-start-2 text-primary m-2 py-2
                    rounded-lg shadow-lg hover:bg-gray-800 active:bg-gray-700"
                    type="submit"
                    onClick={() => {
                      setIsPostEmbo(false)
                    }}
                  >
                    Calculate
                  </button>
                </div>
              </Card>

              <br />
              <Card title={'New Formula 2'}>
                <TagValue
                  size="lg"
                  label="T.E.L."
                  value={(calculatedValues.pre?.formula2.TEL || 0)}
                />
                <TagValue
                  size="lg"
                  label="Seg's 1, 2, 3"
                  percentage={(calculatedValues.pre?.formula2.total_seg_1_2_3 * 100)}
                  value={(calculatedValues.pre?.formula2.total_seg_1_2_3 || 0)}
                />
                <TagValue
                  size="lg"
                  label="Seg's 1, 2, 3, 4"
                  percentage={(calculatedValues.pre?.formula2.total_seg_1_2_3_4 * 100)}
                  value={(calculatedValues.pre?.formula2.total_seg_1_2_3_4 || 0)}
                />
                <TagValue
                  size="lg"
                  label="Seg's 2, 3"
                  percentage={(calculatedValues.pre?.formula2.total_seg_2_3 * 100)}
                  value={(calculatedValues.pre?.formula2.total_seg_2_3 || 0)}
                />
                <TagValue
                  size="lg"
                  label="Whole Liver"
                  percentage={(calculatedValues.pre?.formula2.total_whole_liver * 100)}
                  value={(calculatedValues.pre?.formula2.total_whole_liver || 0)}
                />
              </Card>
            </div>
            <div className="col-span-12 lg:col-span-1">
              <Card title={'Post Embo Liver Volume'}>
                <div className="col-span-6">
                  <div className="grid grid-cols-12 mx-2">
                    <span className="font-bold col-span-5 text-right m-1">CTE Date</span>
                    <div className="col-span-5">
                      <Field name="CTEDate">
                        {({ field, form }) => (
                          <DatePicker
                            {...field}
                            dateFormat="MM-dd-yyyy"
                            className="border p-1 rounded-md col-span-2"
                            id="CTEDate"
                            selected={field.value}
                            onChange={(date) => form.setFieldValue('CTEDate', date)}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="mb-2 text-xs text-red-600">
                    {errors.CTEDate ?? '‎'}
                  </div>
                </div>
                <InputText
                  label="Weight"
                  name="postWeight"
                  placeholder="0"
                  suffix="kg"
                  error={errors.postWeight}
                  touched={touched.postWeight}
                />
                <InputText
                  label="Height"
                  name="postHeight"
                  placeholder="0"
                  suffix="cm"
                  error={errors.postHeight}
                  touched={touched.postHeight}
                />
                <TagValue
                  label="BMI"
                  value={(calculatedValues.post?.formula1.BMI || 0)}
                />
                <InputText
                  label="Liver"
                  name="postLiver"
                  placeholder="0"
                  suffix="cm&#179;"
                  error={errors.postLiver}
                  touched={touched.postLiver}
                />
                <InputText
                  label={isMobile || width <= abbrevWidth ? "S1" : "Segment 1"}
                  name="postSegment1"
                  placeholder="0"
                  suffix="cm&#179;"
                  error={errors.postSegment1}
                  touched={touched.postSegment1}
                />
                <InputText
                  label={isMobile || width <= abbrevWidth ? "S2" : "Segment 2"}
                  name="postSegment2"
                  placeholder="0"
                  suffix="cm&#179;"
                  error={errors.postSegment2}
                  touched={touched.postSegment2}
                />
                <InputText
                  label={isMobile || width <= abbrevWidth ? "S3" : "Segment 3"}
                  name="postSegment3"
                  placeholder="0"
                  suffix="cm&#179;"
                  error={errors.postSegment3}
                  touched={touched.postSegment3}
                />
                <InputText
                  label={isMobile || width <= abbrevWidth ? "S4" : "Segment 4"}
                  name="postSegment4"
                  placeholder="0"
                  suffix="cm&#179;"
                  error={errors.postSegment4}
                  touched={touched.postSegment4}
                />
                <div className="grid grid-cols-3 col-span-6 mb-2">
                  <button
                    className="bg-black font-bold col-span-3 lg:col-span-1 xl:col-start-2 text-primary m-2 py-2 justify-center 
                    rounded-lg shadow-lg hover:bg-gray-800 active:bg-gray-700"
                    type="submit"
                    onClick={() => {
                      setIsPostEmbo(true)
                    }}
                  >
                    Calculate
                  </button>
                </div>
              </Card>

              <br />
              <Card title={'New Formula 2'}>
                <TagValue
                  size="lg"
                  label="T.E.L."
                  value={(calculatedValues.post?.formula2.TEL || 0)}
                />
                <TagValue
                  size="lg"
                  label="Seg's 1, 2, 3"
                  percentage={(calculatedValues.post?.formula2.total_seg_1_2_3 * 100 )}
                  value={(calculatedValues.post?.formula2.total_seg_1_2_3 || 0)}
                />
                <TagValue
                  size="lg"
                  label="Seg's 1, 2, 3, 4"
                  percentage={(calculatedValues.post?.formula2.total_seg_1_2_3_4 * 100)}
                  value={(calculatedValues.post?.formula2.total_seg_1_2_3_4 || 0)}
                />
                <TagValue
                  size="lg"
                  label="Seg's 2, 3"
                  percentage={(calculatedValues.post?.formula2.total_seg_2_3 * 100)}
                  value={(calculatedValues.post?.formula2.total_seg_2_3 || 0)}
                />
                <TagValue
                  size="lg"
                  label="Whole Liver"
                  percentage={(calculatedValues.post?.formula2.total_whole_liver * 100)}
                  value={(calculatedValues.post?.formula2.total_whole_liver || 0)}
                />
              </Card>
            </div>
          </Form>
        )
        }
      </Formik >
      <div className="grid grid-cols-1 lg:grid-cols-3 text-center">
        <div className="bg-gray-100 lg:col-start-2 text-secondary my-2 mx-5 justify-center rounded-lg shadow-lg">
          <h2 className="text-lg bg-secondary p-1 rounded-t-lg text-primary font-bold ">KGR</h2>
          <div className="grid grid-cols-1 mt-3">
            <TagValue
              label="Seg's 1, 2, 3"
              percentage={(calculatedValues.post?.KGR.seg_1_2_3) * 100}
              value={(calculatedValues.post?.KGR.seg_1_2_3 || 0)}
            />
            <TagValue
              label="Seg's 1, 2, 3, 4"
              percentage={(calculatedValues.post?.KGR.seg_1_2_3_4) * 100}
              value={(calculatedValues.post?.KGR.seg_1_2_3_4 || 0)}
            />
            <TagValue
              label="Seg's 2, 3"
              percentage={(calculatedValues.post?.KGR.seg_2_3) * 100}
              value={(calculatedValues.post?.KGR.seg_2_3 || 0)}
            />
          </div>
        </div>
      </div>
    </div >
  );
};

export default Main;

export default function Card (props)
{
    return (
        <div className="grid grid-row bg-gray-50 shadow-lg rounded-lg">
            <span className="text-white text-lg font-bold bg-secondary p-1 rounded-t-lg">
                {props.title}
            </span>
            <div className="grid grid-cols-6 mt-2 mx-2">
                {props.children}
            </div>
        </div>
    )
}
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactDom from 'react-dom';
import './App.css';
import Main from "./components/Form";
import {AboutLVC,AboutBP} from "./screens/about";
import Nav from "./components/Nav/Nav";

const rootElement = document.getElementById('root');
ReactDom.render(<App />, rootElement);

function App() {
  return (
    <Router>
      <div className="App" id="outer-container">
        <Nav outerContainerId={'outer-container'} pageWrapId={'page-wrap'} />
        <div id="page-wrap" className=" h-full w-full">
          <Routes>
            <Route exact path={"/"} element={<Main/>} />
            <Route path="/aboutLVC" element={<AboutLVC/>} />
            <Route path="/aboutBP" element={<AboutBP/>} />
            <Route path="*" element={"Error: 404 Not found"} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import MDACN from "../images/MDACN.png"
import MDACNBuilding from "../images/MDACNBuilding.png"
import liverSVG from "../images/icons/liver.svg"
import BPIconSVG from "../images/icons/BPIcon.svg"
import BPLogo from "../images/BP.png"
import BPCup from "../images/BPCup.png"
export const AboutLVC = () => {

  return (
    <div className="poweredbyBluepeople grid h-full grid-row">
        <div className='grid grid-cols-1 lg:grid-cols-10 text-center'>   
        <div className='lg:col-start-2 lg:col-span-8 grid grid-cols-1 lg:grid-cols-1 py-10 mx-5 pt-20 h-full justify-center gap-5'
        >   
            <div className=''>
                <div className='flex justify-center gap-x-4'>
                    <img src={liverSVG} className='size-3 my-auto max-w-14 max-h-9'></img>
                    <p className='text-3xl font-bold text-center align-middle '>About the Liver Volume Calculator</p>
                </div>
                <hr className='border-black my-2'/>
            </div>
            
            {/*<img src={MDACN} className='mx-auto max-h-16'></img>*/}

            <div>
                <p className='text-lg text-justify'>
                Hepatectomy remains a complex operation with high rates of perioperative morbidity and many of these outcomes may be <span className='font-bold'>due to imprecise estimation</span> of how much liver volume a patient will have following surgery.
                </p>
                <br></br>
                <p className='text-lg text-justify'>
                Over time, we have emphasized <span className='font-bold'>the importance of precise liver volumetry to measure the Future Liver Remnant (FLR)</span> and even more precisely, the standardized FLR to a patient’s body surface area (sFLR). The desired sFLR for safe hepatectomy depends on a patient’s background liver health and quality, exposure to chemotherapy, or the disease being addressed and if inadequate multiple interventions to induce hypertrophy of the sFLR may be offered.
                </p>
                <p className='text-lg text-justify'>
                The estimated sFLR and the rate at which the liver grows in response to these procedures <span className='font-bold'>should be measured as quality control measures and predictors of safe hepatectomy.</span> These steps, starting with volumetry, are critical for safe major hepatectomy and are indispensable for liver surgeons. The team of Hepatobiliary Surgeons in the Department of Surgical Oncology at MD Anderson Cancer Center under the leadership of Professor Jean-Nicolas Vauthey have been steadfast in the incorporation of liver volumetry and subsequent selection of patients for major hepatectomy.  
                </p>
                <br></br>
                <p className='text-lg text-justify'>
                For quite some time, <span className='font-bold'>we have used a table for calculation of sFLR and kinetic growth rate after</span> portal vein embolization that has been widely circulated amongst trainees and colleagues. This application was created to make this resource even more widely available in an easy to use, readily available, and <span className='font-bold'>portable platform.</span>
                </p>
            </div>
            {/*<img src={MDACNBuilding} className='object-cover size-3 lg: h-96 lg: w-full rounded-lg'></img>*/}
        </div>
        </div>
    </div >
  );
};

  export const AboutBP = () => {

    return (
        <div className="poweredbyBluepeople grid h-full grid-row">
            <div className='grid grid-cols-1 lg:grid-cols-10 text-center'>   
                <div className='lg:col-start-2 lg:col-span-8 grid grid-cols-1 lg:grid-cols-2 py-10 mx-5 pt-20 h-full justify-center gap-5'
                >   
                    <div className=''>
                        <div className='flex justify-center gap-x-4'>
                            <img src={BPIconSVG} className='size-3 my-auto max-w-14 max-h-9'></img>
                            <p className='text-3xl font-bold text-center align-middle '>About Blue People</p>
                        </div>
                        <hr className='border-black my-2'/>
                    </div>
                    
                    <img src={BPLogo} className='mx-auto max-h-16'></img>

                    <div>
                        <p className='text-lg text-justify'>
                        Blue People is a <span className='font-bold'>custom software development firm</span> with offices in the US and Mexico. We specialize in agile delivery of digital product and were extremely <span className='font-bold'>happy to build this Liver Volume Calculator</span> for any surgical oncology group or physician that will benefit from being able to calculate T.E.L. and KGR at the patient’s bedside.
                        </p>
                        <br></br>
                        <p className='text-lg text-justify'>
                        Founded on April 6, 2015 in Monterrey, México, our passion and purpose has only continued to grow since then. As we have grown, <span className='font-bold'>we’ve partnered with the Top 5% of Tech Experts worldwide</span> to provide only the best when it comes to serving our customers. We have served start-ups, global corporations, and everything in between. We continuously strive to <span className='font-bold'>stay up-to-date with the latest technology</span> and reach new heights of innovation. The search for reimagination at Blue People is never complete, never ends, and is always just beginning.
                        </p>
                        <br></br>
                        <p className='text-lg text-justify'>
                            <span className='font-bold'>More Information at:</span>
                        </p>
                        <p className='text-lg text-justify'>
                            <a href='https://www.bluepeople.com' className='text-secondary'>https://www.bluepeople.com/</a>
                        </p>
                        <br></br>
                        <p className='text-lg text-justify'>
                            <span className='font-bold'>If you are in Houston, join us at:</span>
                        </p>
                        <p className='text-lg text-justify'>
                            <a href='https://www.bluepeople.com/tech-tequila-talk/' className='text-secondary'>https://www.bluepeople.com/tech-tequila-talk/</a>
                        </p>
                        <br></br>
                        <p className='text-lg text-justify'>
                            <span className='font-bold'>Contact Us:</span>
                        </p>
                        <p className='text-lg text-justify'>
                        Alfredo Arvide
                        </p>
                        <p className='text-lg text-justify'>
                        Chief Innovation Officer, Blue People
                        </p>
                        <p className='text-lg text-justify'>
                        <a href='mailto:aarvide@bluepeople.com' className='text-secondary'>aarvide@bluepeople.com</a>
                        </p>
                    </div>
                    <img src={BPCup} className='object-cover size-3 lg: h-96 lg: w-full rounded-lg'></img>
                </div>
            </div>
        </div >
    );
  };

import { Field } from "formik"
export default function InputText (props)
{
    const { label, name, placeholder, suffix, error, touched } = props
    return (
        <div className="grid grid-row col-span-3">
            <div className="grid grid-cols-5 mx-2">
                <span className="font-bold col-span-2 mx-1 p-1 text-right ">{label}</span>
                <Field
                    type='number'
                    className="border col-span-2 bg-white px-2 rounded-md"
                    id={name}
                    name={name}
                    placeholder={placeholder}
                />
                <span className="m-1 text-muted">{suffix}</span>
            </div>
            <div className="mb-2 text-xs text-red-600 text-center col-span-1 mx-2">
                {(error && touched) ? error : '‎'}
            </div>
        </div>
    )
}
export default function TagValue (props) {
    const {label, percentage = -1, value, suffix, size} = props
    return (
        <div className={`grid grid-cols-12 mb-6 text-black ${size =="lg"?'col-span-6 lg:col-span-3' : 'col-span-3'}`} >
            <span className="font-bold col-span-5 m-1 text-right">
                {label}:
            </span>
            <span className="m-1 col-span-2 font-bold text-tagValue">
                {value.toFixed(1)}
            </span>
            {suffix &&
                <span className="my-1 col-span-3 text-left" >
                    {suffix}
                </span>
            }
            {percentage != -1 ? (
                <>
                    <span className="font-bold border-b border-black m-1 col-span-3">
                        {percentage != 0 ? percentage?.toFixed(2) : ''}
                    </span>
                    <span className="my-1 text-left text-muted">%</span>
                </>
            ) : null
            }
        </div>
    )
}